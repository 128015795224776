<template>
	<div class="card-row-block">
		<router-link class="card-block-link" v-if="routeName" :to="{ name: routeName, params: { id: id } }"></router-link>
		<div class="card-row-content">
			<span v-if="eyebrow" class="eyebrow">{{ type }}</span>
			<h3>{{ title }}</h3>
			<p v-if="text" style="margin-bottom: 10px;">{{ text }}</p>
			<div v-if="tag" class="card-tag">
				<TagIcon class="tag-icon" />
				<span>{{ tag }}</span>
			</div>
		</div>
		<div class="card-row-icon">
			<DocumentIcon style="width: 26px;" v-if="type === 'Document'" />
			<VideoIcon style="width: 30px;" v-if="type === 'Video'" />
            <AudioIcon style="width: 30px;" v-if="type === 'Audio'" />
			<LinkIcon style="width: 31px;" v-if="type === 'Link'" />
		</div>
	</div><!-- card -->
</template>

<script>
import DocumentIcon from '@/assets/document.svg';
import VideoIcon from '@/assets/video.svg';
import AudioIcon from '@/assets/audio.svg';
import LinkIcon from '@/assets/link.svg';
import TagIcon from '@/assets/tag.svg';
export default {
	name: 'CardRow',
	props: {
		title: String,
		text: String,
		tag: String,
		type: String,
		routeName: String,
		eyebrow: Boolean,
        id: Number
	},
	components: {
		DocumentIcon,
		VideoIcon,
        AudioIcon,
		LinkIcon,
		TagIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.card-row-block {
		position: relative;
		padding: 17px;
		border-radius: 12px;
		border: 1px solid $snow;
		display: flex;
		justify-content: space-between;
		border: 1px solid transparent;
		background: $snow;
		align-items: center;
		margin-bottom: 14px;
		&:last-of-type {
			margin-bottom: 0;
		}
		&.card-row-block-resources {
			background: $white;
			border-color: $snow;
			box-shadow: 5px 15px 15px 5px $snow;
			.card-row-content {
				h3 {
					color: $dark;
				}
			}
			.card-row-icon {
				svg g, svg path {
					stroke: $red_base;
				}
				svg .fill {
					fill: $red_base;
					stroke: none;
				}
			}
		}
		&.view-resource {
			background: $red_base;
			border-color: $red_base;
			box-shadow: none;
			.card-row-content {
				h3 {
					color: $white;
				}
				p {
					color: $white;
					margin-bottom: 0;
				}
			}
			.card-row-icon {
				svg g, svg path {
					stroke: $white;
				}
				svg .fill {
					fill: $white;
					stroke: none;
				}
			}
		}
		.card-block-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
		&.card-row-block-alt {
			width: 295px;
			margin-right: 12px;
			flex-shrink: 0;
			min-height: 200px;
			align-items: flex-start;
			margin-bottom: 0;
			box-shadow: none;
			padding-bottom: 50px;
			.card-row-content {
				p {
					color: $mid;
				}
				.card-tag {
					position: absolute;
					bottom: 17px;
					left: 17px;
				}
			}
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
	.card-row-content {
		text-align: left;
		width: 100%;
		padding-right: 20px;
		.card-tag {
			display: flex;
			align-items: center;
			.tag-icon {
			    width: 16px;
				overflow: visible;
				margin-right: 5px;
				.line {
					stroke: $mid;
					stroke-width: 5;
				}
				.dot {
					fill: $mid;
				}
			}
			span {
				font-size: 13px;
				line-height: 16px;
				font-weight: $weight_600;
				color: $mid;
			}
		}
		.eyebrow {
			text-transform: uppercase;
			font-size: 10px;
			opacity: 0.8;
			letter-spacing: 0.83px;
			line-height: 12px;
			color: $slate;
			display: block;
			font-weight: $weight_500;
			margin-bottom: 5px;
		}
		h3 {
			font-size: 17px;
			letter-spacing: -0.5px;
			line-height: 20px;
			color: $slate;
			font-weight: $weight_600;
			margin-bottom: 5px;
		}
		p {
			opacity: 0.9;
			font-size: 14px;
			line-height: 19px;
			color: $slate;
			font-weight: $weight_400;
		}
	}
	.card-row-icon {
		flex-shrink: 0;
		svg {
			display: block;
		}
	}
</style>
