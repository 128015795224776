<template>
	<router-link :to="{ name: route ? route : 'Port', params: { id: id } }" class="port-card" :class="className">
		<img v-if="!add" class="bg" :src="image" />
		<div class="port-card-container">
			<div class="port-card-content">
				<span>{{ port }}</span>
				<p v-if="!add">{{ country }}</p>
			</div>
			<div class="port-card-icon">
				<AnchorIcon v-if="!add" />
				<AddIcon v-if="add" />
			</div>
		</div>
	</router-link>
</template>

<script>
import AnchorIcon from '@/assets/anchor-white.svg';
import AddIcon from '@/assets/add.svg';
export default {
	name: 'PortCard',
	props: {
		country: String,
		port: String,
		add: String,
		className: String,
        route: String,
        id: Number,
        image: String
	},
	components: {
		AnchorIcon,
		AddIcon
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.port-card {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 18px 17px 18px;
		border-radius: 12px;
		background: $dark;
		border-radius: 12px;
		min-height: 150px;
		text-align: left;
		width: 140px;
		min-height: 168px;
		flex-shrink: 0;
		margin-right: 14px;
		&:last-child {
			margin-right: 0;
		}
		img.bg {
			opacity: 0.5;
			border-radius: 12px;
		}
		&.grey {
			background: $snow;
			.port-card-content {
				span {
					color: $slate;
				}
			}
		}
	}
	.port-card-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: auto;
	}
	.port-card-content {
		position: relative;
		span {
			display: block;
			color: $white;
			font-size: 13px;
			font-weight: $weight_600;
			line-height: 17px;
			margin-bottom: 1px;
		}
		p {
			color: $white;
			font-weight: $weight_400;
			font-size: 13px;
			line-height: 17px;
		}
	}
	.port-card-icon {
		position: relative;
		padding-top: 30px;
		svg {
			display: block;
			height: 25px;
			overflow: visible;
			g {
				stroke-width: 3;
			}
			&.add-icon {
				g {
					stroke: $slate;
				}
			}
		}
	}
</style>
