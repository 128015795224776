<template>
<div ref="modalPortSearch" class="phone-modal phone-modal-port-search with-nav" style="bottom: -779px;" :class="{active: isPortSearchModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<AnchorIcon style="width: 25px; height: auto;" class="icon anchor-icon" />
			<span v-if="title">{{ title }}</span>
			<span v-else>Search Ports</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortSearchModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="field field-icon field-icon-left">
		<div class="control">
			<input ref="searchInput" class="search-field" placeholder="Type to search..." type="text" v-model="query" enterkeyhint="search">
			<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
		</div>
	</div>
	<div class="section-gap section-port-list">
		<div class="section-title-wrap">
			<h2 v-if="show" class="section-title">{{ ports.count }} Result{{ ports.count && ports.count > 1 ? 's' : '' }}</h2>
            <h2 v-else class="section-title">Recent search results</h2>
		</div>
        <div v-if="show" class="port-list-wrap section-scroll">
            <PortListBlock
                v-for="port in ports.results"
                :key="port.id"
                :port="port.name"
                :country="port.country"
                @click.native="closePortSearchModal"
                :id="port.id"
                :countryCode="port.country_code"
            />
        </div><!-- port list wrap -->
        <div v-else class="port-list-wrap section-scroll">
            <PortListBlock
                v-for="port in recentSearchResults"
                :key="port.id"
                :port="port.name"
                :country="port.country"
                @click.native="closePortSearchModal"
                :id="port.id"
                :countryCode="port.country_code"
            />
        </div><!-- port list wrap -->
	</div>
</div><!-- modal -->
</template>

<script>
import AnchorIcon from '@/assets/anchor-black.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import SearchIcon from '@/assets/search.svg';
import PortListBlock from '@/components/PortListBlock.vue';
import PortService from '@/services/PortService';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
export default {
name: 'PortSearchOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String,
        active: Boolean
	},
	data () {
		return {
            delay: null,
            query: '',
            ports: [],
            show: false
		};
	},
	components: {
		AnchorIcon,
		GreyCloseIcon,
		SearchIcon,
		PortListBlock
	},
    watch: {
        query () {
            clearTimeout(this.delay);
            this.delay = setTimeout(() => {
                if (this.query) {
                    let portSearchRequest = PortService.search(this.query);
                    if (this.active) {
                        portSearchRequest = PortService.searchActive(this.query);
                    }
                    portSearchRequest.then((response) => {
                        this.show = true;
                        this.ports = response.data;
                        if (this.ports.count) {
                            this.ports.results.forEach((port) => {
                                if (this.recentSearchResults.some(p => p.id === port.id)) {
                                    return;
                                }
                                this.recentSearchResults.push(port);
                            });
                            localStorage.setItem('recent_search_results', JSON.stringify(this.recentSearchResults));
                        }
                    });
                } else {
                    this.ports = [];
                    this.show = false;
                }
            }, 500);
        }
    },
    computed: {
        isPortSearchModalOpen () {
            if (this.$store.state.isPortSearchModalOpen) {
                this.$refs.searchInput.focus();
                return true;
            }
            return false;
        },
        recentSearchResults () {
            return this.$store.state.recentSearchResults;
        }
    },
	methods: {
        closePortSearchModal () {
        	this.$store.commit('PORT_SEARCH_TOGGLE');
        }
    },
    mounted () {
        if (Capacitor.platform !== 'web') {
            Keyboard.setAccessoryBarVisible({
                isVisible: true
            });
            Keyboard.setResizeMode({
                mode: KeyboardResize.None
            });
            Keyboard.addListener('keyboardWillShow', () => {
                this.$refs.modalPortSearch.style.height = '90%';
            });
            Keyboard.addListener('keyboardWillHide', () => {
                this.$refs.modalPortSearch.style.height = 'unset';
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.phone-modal.phone-modal-port-search.active {
		bottom: 0!important;
	}
	.section-title-wrap .section-title {
		color: $grey;
	}
	.section-scroll {
		overflow: scroll;
	    position: relative;
	    height: 400px;
	    padding-bottom: 100px;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $blue_dark;
				}
			}
			span {
				color: $blue_dark;
			}
		}
	}
    .search-field {
        padding: 0 50px !important;
    }
</style>
