<template>
	<div class="news-card">
		<router-link class="card-block-link" :to="{ name: 'NewsPost', params: { id: id } }"></router-link>
		<div class="news-card-hold">
			<div class="news-card-media">
                <img class="bg" v-if="image" :src="image" />
				<img v-else class="news-logo" src="@/assets/tmts.png" />
			</div>
			<div class="news-card-content">
				<h3>{{ title }}</h3>
				<span class="date">{{ date }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: {
		title: String,
		date: String,
		id: Number,
        image: String
	},
	components: {
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.news-card {
		position: relative;
		display: flex;
		flex-direction: column;
		text-align: left;
		width: 294px;
		flex-shrink: 0;
		margin-right: 14px;
		&:last-of-type {
			margin-right: 0;
		}
		.card-block-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	.news-card-content {
		h3 {
			position: relative;
			font-size: 17px;
			color: $dark;
			font-weight: $weight_600;
			line-height: 22px;
			letter-spacing: -0.5px;
			margin-bottom: 8px;
		}
		.date {
			display: block;
			text-transform: uppercase;
			font-size: 10px;
			line-height: 12px;
			font-weight: $weight_500;
			color: $mid;
			letter-spacing: 0.83px;
		}
	}
	.news-card-media {
		position: relative;
		height: 173px;
		border-radius: 12px;
		margin-bottom: 12px;
		background: $grey;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		img.news-logo {
			width: 100px;
		}
	}
	.news-card-alt {
		width: 100%;
		margin-right: 0;
		margin-bottom: 12px;
		.news-card-hold {
			display: flex;
		}
		.news-card-media {
			width: 110px;
			height: 110px;
			flex-shrink: 0;
			margin-right: 20px;
			margin-bottom: 0;
			img.news-logo {
				width: 60px;
			}
		}
	}
</style>
