<template>
	<div class="card-block">
	<router-link class="card-block-link" v-if="routeName" :to="{ name: routeName }"></router-link>
    <a v-else-if="link" @click="openBrowser(link)" class="card-block-link" target="_blank"></a>
		<div class="card-block-content">
			<span v-if="category" class="eyebrow">{{ category }}</span>
			<h3>{{ title }}</h3>
			<p>{{ text }}</p>
		</div>
		<div class="card-block-icon">
			<ChatIcon style="width: 32px;" v-if="icon === 'ChatIcon'" />
			<CheckInIcon style="width: 31px;" v-if="icon === 'CheckInIcon'" />
			<SendIcon style="width: 32px;" v-if="icon === 'SendIcon'" />
			<ShipIcon style="width: 35px;" v-if="icon === 'ShipIcon'" />
			<DonateIcon style="width: 32px;" v-if="icon === 'DonateIcon'" />
			<ContactIcon style="width: 30px;" v-if="icon === 'ContactIcon'" />
			<AnchorIcon style="width: 27px;" v-if="icon === 'AnchorIcon'" />
			<BookIcon style="width: 30px;" v-if="icon === 'BookIcon'" />
			<StarIcon style="width: 30px;" v-if="icon === 'StarIcon'" />
			<FaqIcon style="width: 30px;" v-if="icon === 'FaqIcon'" />
			<DownloadIcon style="width: 30px;" v-if="icon === 'DownloadIcon'" />
			<TickIcon style="width: 30px;" v-if="icon === 'TickIcon'" />
			<PencilIcon style="width: 30px;" v-if="icon === 'PencilIcon'" />
			<FolderIcon style="width: 30px;" v-if="icon === 'FolderIcon'" />
		</div>
	</div><!-- card -->
</template>

<script>
import ChatIcon from '@/assets/chat.svg';
import CheckInIcon from '@/assets/check-in.svg';
import SendIcon from '@/assets/send.svg';
import ShipIcon from '@/assets/ship.svg';
import DonateIcon from '@/assets/donate.svg';
import ContactIcon from '@/assets/contact.svg';
import AnchorIcon from '@/assets/anchor-white.svg';
import BookIcon from '@/assets/book.svg';
import StarIcon from '@/assets/star.svg';
import FaqIcon from '@/assets/faq.svg';
import DownloadIcon from '@/assets/download.svg';
import TickIcon from '@/assets/tick.svg';
import PencilIcon from '@/assets/pencil.svg';
import FolderIcon from '@/assets/folder.svg';
import { Browser } from '@capacitor/browser';
export default {
	name: 'Card',
	props: {
		category: String,
		title: String,
		text: String,
		icon: String,
		routeName: String,
        link: String
	},
	components: {
		ChatIcon,
		CheckInIcon,
		SendIcon,
		ShipIcon,
		DonateIcon,
		ContactIcon,
		AnchorIcon,
		StarIcon,
		BookIcon,
		FaqIcon,
		TickIcon,
		PencilIcon,
		DownloadIcon,
		FolderIcon
	},
    methods: {
        async openBrowser (url) {
            await Browser.open({
                url: url,
                presentationStyle: 'popover'
            });
        }
    }
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.card-block {
        cursor: pointer;
		position: relative;
		padding: 18px 17px 18px;
		border-radius: 12px;
		width: calc(50% - 10px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		&.card-block-full {
			width: 100%;
			flex-direction: row;
			.card-block-icon {
				padding-top: 0;
				flex-shrink: 0;
			}
			.card-block-content {
				padding-right: 20px;
			}
		}
		&.card-block-purple {
			background: $purple_light;
			.eyebrow, h3, p {
				color: $purple_dark;
			}
			svg {
				g, path {
					stroke: $purple_dark;
				}
				.fill {
					fill: $purple_dark;
				}
			}
		}
		&.card-block-red {
			background: $red_light;
			.eyebrow, h3, p {
				color: $red_dark;
			}
			svg {
				g, path {
					stroke: $red_dark;
				}
				.fill {
					fill: $red_dark;
				}
			}
			&.filled {
				svg path {
					fill: $red_dark;
				}
			}
		}
		&.card-block-grey {
			background: $snow;
			.eyebrow, h3, p {
				color: $slate;
			}
			svg {
				g, path {
					stroke: $slate;
				}
				.fill {
					fill: $slate;
				}
				.word {
					stroke-width: 0;
					fill: $slate;
				}
			}
		}
		&.card-block-dark-grey {
			background: $grey;
			.eyebrow, h3, p {
				color: $white;
			}
			svg {
				g, path {
					stroke: $white;
				}
				.fill {
					fill: $white;
				}
				.word {
					stroke-width: 0;
					fill: $white;
				}
			}
		}
		&.card-block-cyan-filled {
			background: $cyan_base;
			.eyebrow, h3, p {
				color: $white;
			}
			svg {
				g, path {
					stroke: $white;
				}
				.fill {
					fill: $white;
				}
			}
		}
		&.card-block-purple-filled {
			background: $purple_base;
			.eyebrow, h3, p {
				color: $white;
			}
			svg {
				g, path {
					stroke: $white;
				}
				.fill {
					fill: $white;
				}
			}
		}
		&.card-block-yellow {
			background: $yellow_light;
			.eyebrow, h3, p {
				color: $yellow_dark;
			}
			svg {
				g, path {
					stroke: $yellow_dark;
				}
				.fill {
					fill: $yellow_dark;
				}
			}
		}
		&.card-block-orange {
			background: $orange_light;
			.eyebrow, h3, p {
				color: $orange_dark;
			}
			svg {
				g, path {
					stroke: $orange_dark;
				}
				.fill {
					fill: $orange_dark;
				}
			}
		}
		&.card-block-sky {
			background: $sky_light;
			.eyebrow, h3, p {
				color: $sky_dark;
			}
			svg {
				g, path {
					stroke: $sky_dark;
				}
				.fill {
					fill: $sky_dark;
				}
			}
		}
		&.card-block-blue {
			background: $blue_light;
			.eyebrow, h3, p {
				color: $blue_dark;
			}
			svg {
				g, path {
					stroke: $blue_dark;
				}
				.fill {
					fill: $blue_dark;
				}
			}
		}
		&.card-block-green {
			background: $green_light;
			.eyebrow, h3, p {
				color: $green_dark;
			}
			svg {
				g, path {
					stroke: $green_dark;
				}
				.fill {
					fill: $green_dark;
				}
			}
		}
		&.card-block-green-filled {
			background: $green_base;
			.eyebrow, h3, p {
				color: $white;
			}
			svg {
				g, path {
					stroke: $white;
				}
				.fill {
					fill: $white;
				}
			}
		}
	}
	.card-block-link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.card-block-content {
		text-align: left;
		width: 100%;
		.eyebrow {
			text-transform: uppercase;
			font-size: 10px;
			opacity: 0.8;
			letter-spacing: 0.83px;
			line-height: 12px;
			color: $slate;
			display: block;
			font-weight: $weight_500;
			margin-bottom: 5px;
		}
		h3 {
			font-size: 17px;
			letter-spacing: -0.5px;
			line-height: 20px;
			color: $slate;
			font-weight: $weight_600;
			margin-bottom: 5px;
		}
		p {
			opacity: 0.9;
			font-size: 14px;
			line-height: 19px;
			color: $slate;
			font-weight: $weight_400;
		}
	}
	.card-block-icon {
		padding-top: 30px;
		svg {
			display: block;
		}
	}
</style>
